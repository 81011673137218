.logo {
  position: absolute;
  left: 50%;
  top:10px;
  transform: translate(-50%, 0);
  width:200px;
}

.navbarToggler {
  padding: 4px !important;
}
