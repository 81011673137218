.container {
  font-size: 0.875rem;
  background-color: white;
  min-height: calc(100vh - 71px);
}

.mainContainer {
  width: 86% !important;
}

.placeholderImage {
  max-width: 320px;
}

.list {
  height: 100% !important;
  padding: 0.8rem;
  overflow-y: auto;
}

.row {
  height: calc(100vh - 56px - 38px - 3rem); // 56px: ヘッダー, 38px: タイトル, マージン合計: 3rem
}
