.row {
  height: calc(100vh - 94px - 1.5rem); // 94px:ヘッダー+topContents, 1.5rem:ヘッダーとtopContents間のマージン
}

.topContents {
  height: 38px;
}

.list {
  padding: 0.8rem;
  overflow: auto;
}

$footer: 70px;
.memberDetail {
  height: calc(100% - #{$footer});
  overflow-y: auto;
}

.skillBadgeContainer {
  border: 1px solid #dee2e6;
  min-height: 2.5rem;
}

.placeholderImage {
  max-width: 320px;
}
