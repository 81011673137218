.button {
  width: 350px;
}

.cautionTextColor {
  color: #0075e3;
}

.loginLink {
  position: relative;
  top: 2rem;
  height: 1rem;
  margin-bottom: -1rem;
  width: 100%;
  text-align: center;
}
