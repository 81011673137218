.list {
  top: 71px;
  height: calc(100vh - 150px) !important;
  padding: 0.8rem;
  overflow: auto;
}

.placeholder {
  img {
    width: 240px;
    max-width: 320px;
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5rem;
  }

  p {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}
