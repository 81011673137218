.leftBlock {
  flex: 0 0 auto;
  width: 290px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1.5px;
    height: 100%;
    box-shadow: 1.5px 0 1.5px var(--bs-secondary-middle);
    pointer-events: none;
  }

  table {
    border-collapse: collapse;

    td {
      border-right: 1px solid var(--bs-secondary-pale);
      border-bottom: 1px solid var(--bs-secondary-pale);
      height: 45px;

      &.main {
        width: 100px;
      }

      &.sub {
        width: 190px;
      }
    }
  }
}

.rightBlock {
  flex: 0 0 auto;
  width: 140px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 1.5px;
    height: 100%;
    box-shadow: -1.5px 0 1.5px var(--bs-secondary-middle);
    pointer-events: none;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 140px;
    td {
      border-bottom: 1px solid var(--bs-secondary-pale);
      width: 140px;
      height: 45px;
      text-align: right;
    }
  }
}

.centerBlock {
  overflow-x: auto;
  display: flex;

  table {
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    width: 140px;

    td {
      border-right: 1px solid var(--bs-gray);
      border-bottom: 1px solid var(--bs-secondary-pale);
      width: 140px;
      height: 45px;
      text-align: right;
      padding: 0;
      overflow: hidden;
    }
  }
}

/* 色指定 */
.bgColorTotal {
  background-color: var(--bs-primary-superpale) !important;
}

.bgColorProfit {
  background-color: var(--bs-primary-pale) !important;
}

.colorEstimate {
  color: var(--bs-gray-dark);
}

.workspaceName {
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
