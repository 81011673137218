.barContainer {
  background-color: #e8ebf0;
  height: 12px;
  width: 100%;
  overflow: hidden;
}

.cardContainer {
  min-width: 340px !important;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 0.175rem 0.5rem rgba(0, 0, 0, 0.075) !important;
}
