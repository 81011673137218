.barContainer {
  background-color: #e8ebf0;
  height: 12px;
  width: 100%;
  overflow: hidden;
}

.badgeButtonContainer {
  width: calc(100% - 2rem);
}
