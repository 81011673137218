.container {
  height: 100vh;
  background-color: white;
}

.header {
  height: 71px;
  border-bottom: solid 2px var(--bs-secondary-pale);
}

.main {
  padding: 50px 100px;
}

.tableWrapper {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.tableContent {
  font-size: 0.875rem;
  height: 50px;
  position: relative;
  background-color: white;
  padding: 0;
  z-index: 1;
}

.table {
  width: 100%;
  table-layout: fixed;

  th,
  td {
    font-weight: normal;
    width: 80px;
    height: 40px;
    background-color: white;
    border-bottom: 1px solid var(--bs-secondary-pale);
  }
}

.footer {
  padding-top: 14px;
  height: 71px;
  border-top: solid 2px var(--bs-secondary-pale);
}
