.base {
  padding: 0.1rem 0.6rem !important;
  margin: 0 0 0.5rem 0.5rem;
  border-radius: 0.8rem;
  font-size: 0.875rem;
  max-width: 100%;
}

.toggle_button {
  @extend .base;
  border: solid 0.08rem #dcdcdc;
  color: #909090;
  cursor: pointer;
  &:hover {
    color: #ffffff;
    background-color: #0075e3;
    border: solid 0.08rem #0075e3;
  }
}

.toggle_button_tablet {
  @extend .base;
  border: solid 0.08rem #dcdcdc;
  color: #909090;
}

.toggle_button_active {
  @extend .base;
  border: solid 0.08rem #0075e3;
  background-color: #0075e3;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.toggle_button_disabled {
  @extend .base;
  border: solid 0.08rem #dcdcdc;
  background-color: #dcdcdc;
}

.square {
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 0.2rem;
  border: 0.09rem solid #ffffff;
}
