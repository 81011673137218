.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 99998;
}

.spinner {
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: calc(50% - 1.5rem);
  left: calc(50% - 1.5rem);
  z-index: 99999;
}
