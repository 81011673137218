$tableHeight: 239px;
.tableWrapper {
  max-width: max-content;
  max-height: calc(100vh - #{$tableHeight});
  overflow: auto;
  border: 1px solid var(--bs-secondary-pale);
}

.timeHeader {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  border-bottom: 1px solid var(--bs-secondary-pale);
}

.tableRow {
  height: 2.5rem;
  overflow-y: clip;
  border-top: 1px solid var(--bs-secondary-pale);
  &:hover .tableWorkHeader {
    background-color: var(--bs-primary-pale);
  }
  &:hover .tableContent {
    background-color: var(--bs-primary-pale);
  }
  &:hover .tableTotalHeader {
    background-color: var(--bs-primary-pale);
  }
}

.tableWorkHeader {
  min-width: 200px;
  max-width: 200px;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: #ffffff;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 1.5px 0 1.5px var(--bs-secondary-middle);
  }
}
.tableTotalHeader {
  min-width: 100px;
  max-width: 100px;
  text-align: center;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 2;
  background-color: #ffffff;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-shadow: -1.5px 0 1.5px var(--bs-secondary-middle);
  }
}

.tableContent {
  position: relative;
  padding: 0;
  z-index: 1;
}

.square {
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 0.2rem;
  border: 0.09rem solid #ffffff;
}
