.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 350px;
  max-width: 70%;
  height: 100%;
  padding: 60px 10px;
  font-size: 0.875rem;
  background: #f8fcff;
  transition: all 0.2s ease-in-out 0s;
  transform: translateX(-100%);
  .open & {
    transform: translateX(0);
    box-shadow: 6px 0 25px rgba(0, 0, 0, 0.16);
  }
}

.sidebarOpen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 350px;
  max-width: 70%;
  height: 100%;
  padding: 60px 10px;
  font-size: 0.875rem;
  background: #f8fcff;
  transition: all 0.2s ease-in-out 0s;
  transform: translateX(0);
  box-shadow: 6px 0 25px rgba(0, 0, 0, 0.16);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
}

.cardBody {
  width: 78px;
  height: 74px;
  cursor: pointer;
  align-items: center;
  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35);
  }
}
