.filterToggle {
  width: 42px;
  height: 38px;
}

.popup {
  min-width: 200px;
  box-shadow: 0 4px 8px rgba(18, 18, 18, 0.15);
  font-size: 0.875rem;
  line-height: 1.5rem;
  z-index: 2000;
  top: 30px;
  right: 30px;
}
