@import './variables.scss';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icf-shift {
  &:before {
    content: $icf-shift;
  }
}
.icf-comment {
  &:before {
    content: $icf-comment;
  }
}
.icf-relation {
  &:before {
    content: $icf-relation;
  }
}
.icf-downToUp {
  &:before {
    content: $icf-downToUp;
  }
}
.icf-upToDown {
  &:before {
    content: $icf-upToDown;
  }
}
.icf-leftToRight {
  &:before {
    content: $icf-leftToRight;
  }
}
.icf-rightToLeft {
  &:before {
    content: $icf-rightToLeft;
  }
}
.icf-larger {
  &:before {
    content: $icf-larger;
  }
}
.icf-smaller {
  &:before {
    content: $icf-smaller;
  }
}
.icf-percent {
  &:before {
    content: $icf-percent;
  }
}
.icf-setting {
  &:before {
    content: $icf-setting;
  }
}
.icf-template {
  &:before {
    content: $icf-template;
  }
}
.icf-list {
  &:before {
    content: $icf-list;
  }
}
.icf-save {
  &:before {
    content: $icf-save;
  }
}
.icf-cal {
  &:before {
    content: $icf-cal;
  }
}
.icf-double_carot {
  &:before {
    content: $icf-double_carot;
  }
}
.icf-amount {
  &:before {
    content: $icf-amount;
  }
}
.icf-by_time {
  &:before {
    content: $icf-by_time;
  }
}
.icf-compare {
  &:before {
    content: $icf-compare;
  }
}
.icf-delete {
  &:before {
    content: $icf-delete;
  }
}
.icf-edit {
  &:before {
    content: $icf-edit;
  }
}
.icf-filter {
  &:before {
    content: $icf-filter;
  }
}
.icf-Move {
  &:before {
    content: $icf-Move;
  }
}
.icf-others {
  &:before {
    content: $icf-others;
  }
}
.icf-member .path1 {
  &:before {
    content: $icf-member-path1;
    color: rgb(108, 117, 125);
  }
}
.icf-member .path2 {
  &:before {
    content: $icf-member-path2;
    margin-left: -1em;
    color: rgb(108, 117, 125);
  }
}
.icf-member .path3 {
  &:before {
    content: $icf-member-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icf-member .path4 {
  &:before {
    content: $icf-member-path4;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icf-plus {
  &:before {
    content: $icf-plus;
  }
}
.icf-replace {
  &:before {
    content: $icf-replace;
  }
}
.icf-schedule {
  &:before {
    content: $icf-schedule;
  }
}
.icf-search {
  &:before {
    content: $icf-search;
  }
}
.icf-updated {
  &:before {
    content: $icf-updated;
  }
}
.icf-check {
  &:before {
    content: $icf-check;
  }
}
.icf-hint {
  &:before {
    content: $icf-hint;
  }
}
.icf-info {
  &:before {
    content: $icf-info;
  }
}
.icf-alert {
  &:before {
    content: $icf-alert;
  }
}
.icf-Menu {
  &:before {
    content: $icf-Menu;
  }
}
.icf-close {
  &:before {
    content: $icf-close;
  }
}
.icf-carot_down {
  &:before {
    content: $icf-carot_down;
  }
}
.icf-carot_left {
  &:before {
    content: $icf-carot_left;
  }
}
.icf-carot_right {
  &:before {
    content: $icf-carot_right;
  }
}
.icf-carot_up {
  &:before {
    content: $icf-carot_up;
  }
}
.icf-chevron_left {
  &:before {
    content: $icf-chevron_left;
  }
}
.icf-chevron_right {
  &:before {
    content: $icf-chevron_right;
  }
}
