.barContainer {
  background-color: #e8ebf0;
  height: 12px;
  width: 80%;
  overflow: hidden;
}
.mainBar {
  height: 100%;
}
.border {
  position: relative;
  top: -12px;
  width: 1px;
  height: 100%;
  background-color: black;
}
.rate {
  text-align: right;
  width: 18%;
}
