.row {
  height: calc(100vh - 94px - 1.5rem); // 94px:ヘッダー+topContents, 1.5rem:ヘッダーとtopContents間のマージン
}

.topContents {
  height: 38px;
}

.list {
  padding: 0.8rem;
  overflow-y: auto;
}

$footer: 70px;
.shiftDetail {
  height: calc(100% - #{$footer});
  overflow-y: auto;

  .top {
    height: 77px;
  }

  .workerSchedule {
    height: calc(100% - 77px - 1rem);
    overflow-y: auto;
  }
}

.placeholderImage {
  max-width: 320px;
}
