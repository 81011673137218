.notification {
  position: sticky;
  top: 56px;
  z-index: 1000;
}

.container {
  overflow: hidden;
  height: 2rem;
  width: 100%;
  position: relative;
  margin-bottom: -2rem;
}

%base {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -2rem;
  height: 100%;
  transition-property: top;
  transition-duration: 0.5s;
}

.success {
  @extend %base;
  background-color: var(--bs-success);
  color: var(--bs-success);
  &[data-show='true'] {
    top: 0;
    color: white;
  }
}

.error {
  @extend %base;
  background-color: var(--bs-danger);
  color: var(--bs-danger);
  &[data-show='true'] {
    top: 0;
    color: white;
  }
}

.warning {
  @extend %base;
  background-color: var(--bs-warning);
  color: var(--bs-warning);
  &[data-show='true'] {
    top: 0;
    color: black;
  }
}
