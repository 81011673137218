.cardPerSeven {
  width: calc(100% / 7);
}

.cardPerEight {
  width: calc(100% / 8);
}

.pseudo-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
}

.unscheduledSpace {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  height: 30vh;
  overflow-y: scroll;
}

.bottomOffset {
  bottom: 4rem;
}
