.default {
  border: solid 1px var(--bs-light-gray);
  border-radius: 5px;
}

.focus {
  @extend .default;
  background-color: var(--bs-primary-pale);
}

.selected {
  @extend .default;
  background-color: var(--bs-primary);
  color: #ffffff;
}

.changed {
  @extend .default;
  background-color: var(--bs-secondary-pale);
  border-color: var(--bs-primary);
}

.disabled {
  @extend .default;
  background-color: var(--bs-secondary-pale);
}
