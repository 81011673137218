.table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.rightShadow {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    box-shadow: 1.5px 0 1.5px var(--bs-secondary-middle);
  }
}
.col {
  @extend .rightShadow;
  background-color: white;
  position: sticky;
  left: 0;
  font-weight: normal;
  width: 220px !important;
  min-width: 220px;
}
.header {
  position: sticky;
  top: 0;
  z-index: 2;
}
.nameHeader {
  @extend .col;
  background-color: var(--bs-secondary-pale);
  background-clip: padding-box;
  z-index: 2 !important;
  padding: 0 0 0 1.6rem;
}
.groupLabel {
  @extend .col;
}
.groupRow {
  border-bottom: 1px solid var(--bs-secondary-pale);
  background-clip: padding-box;
}
.workerLabel {
  @extend .col;
}
.workerRow {
  border-bottom: 1px solid var(--bs-secondary-pale);
}
