.graphContainer {
  height: 50px;
  position: relative;
  display: flex;
  border-bottom: 1px solid var(--bs-secondary-pale);
}

.scaleBase {
  flex: 1;
  height: 100%;
  max-width: 20px;
}

.scaleEven {
  @extend .scaleBase;

  &:nth-child(odd) {
    border-left: 1px solid var(--bs-secondary-pale);
  }

  &:nth-child(even) {
    border-left: 1px dotted var(--bs-secondary-pale);
  }
}

.scaleOdd {
  @extend .scaleBase;

  &:nth-child(odd) {
    border-left: 1px dotted var(--bs-secondary-pale);
  }

  &:nth-child(even) {
    border-left: 1px solid var(--bs-secondary-pale);
  }
}

.scaleEven0 {
  @extend .scaleEven;

  &:nth-child(4n) {
    border-right: 1px solid var(--bs-secondary-middle-pale);
  }

  &:nth-child(4n + 1) {
    border-left: 1px solid var(--bs-secondary-middle-pale);
  }
}

.scaleOdd15 {
  @extend .scaleOdd;

  &:nth-child(4n - 1) {
    border-right: 1px solid var(--bs-secondary-pale);
  }

  &:nth-child(4n) {
    border-left: 1px solid var(--bs-secondary-pale);
  }
}

.scaleEven30 {
  @extend .scaleEven;

  &:nth-child(4n + 2) {
    border-right: 1px solid var(--bs-secondary-pale);
  }

  &:nth-child(4n + 3) {
    border-left: 1px solid var(--bs-secondary-pale);
  }
}

.scaleOdd45 {
  @extend .scaleOdd;

  &:nth-child(4n + 1) {
    border-right: 1px solid var(--bs-secondary-pale);
  }

  &:nth-child(4n + 2) {
    border-left: 1px solid var(--bs-secondary-pale);
  }
}
