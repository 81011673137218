.container {
  font-size: 0.875rem;
  background-color: white;
  min-height: calc(100vh - 71px);
}

.link {
  color: var(--bs-primary);

  &:hover {
    color: var(--bs-primary-dark);
    text-decoration: underline;
    cursor: pointer;
  }
}
