.badgeColor {
  &Costs {
    background-color: var(--bs-rose) !important;
  }
  &Sales {
    background-color: var(--bs-primary-middle) !important;
  }
}

.innerBadgeLabel {
  top: 0;
  left: 0;
  z-index: 5; // カーソルを合わせるとバッジが隠れてしまうため、予めz-indexを指定しておく
}

.inputPaddingLeft {
  padding-left: 75px !important; // 費用・売上ラベルと重ならない幅
}

.unitCostMarginLeft {
  margin-left: -5% !important; // 単価inputとその他inputの左端を揃える
}
