body {
  margin: 0;
  color: var(--bs-black);
  background-color: var(--bs-bgcolor);
  font-family: -apple-system, BlinkMacSystemFont, 'Meiryo', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* position */
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

/* font-size */
.font-xx-large {
  font-size: 2.5rem;
}
.font-x-large {
  font-size: 1.5rem;
}
.font-large {
  font-size: 1.125rem;
}
.font-middle {
  font-size: 1rem;
}
.font-small {
  font-size: 0.875rem;
}
.font-x-small {
  font-size: 0.75rem;
}

.btn-success {
  --bs-btn-color: #fff;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c62a34;
  --bs-btn-disabled-color: #fff;
  --bs-btn-active-color: #fff;
}

.btn-outline-danger {
  --bs-btn-hover-color: #fff;
  --bs-btn-active-color: #fff;
}
