.container {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: 70vh;
  gap: 1rem;
}

.workspaceRow {
  height: 2.5rem;
  overflow-y: clip;
  border-top: 1px solid var(--bs-secondary-pale);
  user-select: none;
}

.scheduleRow {
  @extend .workspaceRow;
  &:hover .scheduleCol,
  &:hover .scheduleEdit,
  &:hover .scheduleSumCol {
    background-color: var(--bs-primary-pale);
  }
}

.header {
  min-width: 200px;
  max-width: 200px;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: var(--bs-secondary-pale);

  i {
    color: var(--bs-secondary);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    box-shadow: 1.5px 0 1.5px var(--bs-secondary-middle);
  }
}

.sumHeader {
  @extend .header;
  min-width: 100px;
  right: 0;
  background-color: var(--bs-secondary-pale);
  text-align: center;
  &::after {
    left: 0;
  }
}

.scheduleSumCol {
  @extend .sumHeader;
  background-color: white;
}

.workspaceCol {
  @extend .header;
  background-color: white;
  &::after {
    box-shadow: none;
  }
}

.scheduleCol {
  @extend .header;
  background-color: white;
  padding-left: 2.7rem;
  padding-right: 1rem;
  &::after {
    box-shadow: 1.5px 0 1.5px var(--bs-secondary-middle);
  }
}

.scheduleEdit {
  @extend .workspaceRow;
}

.stickyLeft {
  left: 0;
  position: sticky;
}
