.container {
  height: 38px;
  display: flex;
  background-color: var(--bs-secondary-pale);
  white-space: nowrap !important;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
}
.hourBorderRight {
  border-right: 2px solid var(--bs-secondary-middle);
  &:last-child {
    border-right: none;
  }
}
.borderRight {
  border-right: 1px solid var(--bs-secondary-middle);
}
.borderLeft {
  padding-left: 5px;
  border-left: 1px solid var(--bs-secondary-middle);
}

$half-cell-width: 10px;
.timeText {
  position: relative;
  left: $half-cell-width * 3;
}
