.table {
  width: 100%;
  table-layout: fixed;

  th {
    font-weight: normal;
    height: 2.5rem;
    border-bottom: 1px solid var(--bs-secondary-pale);
  }

  td {
    height: 2.5rem;
    border-bottom: 1px solid var(--bs-secondary-pale);
  }
}

.labelWrapper {
  width: 12.5rem;
  z-index: 20;
}

.teamHeader {
  background-clip: padding-box;
  box-shadow: 1.5px 0 1.5px var(--bs-secondary-middle);
}

.label {
  padding: 0.5rem 0.5rem 0.5rem 1.6rem;
  background-clip: padding-box;
  box-shadow: 1.5px 0 1.5px var(--bs-secondary-middle);
}

$icon-font-size: 1.125rem;

.workerName {
  width: calc(100% - #{$icon-font-size});
}

.scheduleTypesOpener {
  font-size: $icon-font-size;
}

.popupCardBody {
  font-size: 0.875rem;
  max-width: 200px;
}

.dataWrapper {
  flex: 1;
  overflow-x: scroll;
  z-index: 10;
}

.dataCell {
  width: 5rem;
}
