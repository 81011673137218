.list {
  height: 100% !important;
  padding: 0.8rem;
  overflow-y: auto;
}

.notification {
  position: sticky;
  top: 56px;
  z-index: 1000;
}

.row {
  height: calc(100vh - 56px - 38px - 3rem); // 56px: ヘッダー, 38px: タイトル, マージン合計: 3rem
}

.rowNoButton {
  height: calc(100vh - 56px - 36px - 3rem); // 追加ボタンなしの場合 56px: ヘッダー, 36px: タイトル, マージン合計: 3rem
}
