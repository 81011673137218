.container {
  font-size: 0.875rem;
  background-color: white;
  // min-height: 100vh;
}

$header-height: 69px;
$footer-height: 71px;
.body {
  height: calc(100vh - $header-height - $footer-height);
  overflow-y: scroll;
  padding: 0 25%;
}
