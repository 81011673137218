.icon {
  min-width: 30px;
  text-align: center;
  line-height: 30px;
  color: white;
  font-weight: bold;
  margin: auto 0;
}

$color_list: 'silver', 'twilight', 'sky', 'emerald', 'magenta', 'bengal', 'mustard', 'rose', 'violet', 'grass';

@each $color in $color_list {
  .#{$color}-icon-01 {
    @extend .icon;
    background: url('./01-#{$color}.svg') no-repeat;
  }
}

@each $color in $color_list {
  .#{$color}-icon-02 {
    @extend .icon;
    background: url('./02-#{$color}.svg') no-repeat;
  }
}

@each $color in $color_list {
  .#{$color}-icon-03 {
    @extend .icon;
    background: url('./03-#{$color}.svg') no-repeat;
  }
}
