.input {
  max-width: 100px;
}

.toolTip {
  font-size: 0.75rem;
  background-color: black;
  color: white;
  padding: 5px 10px 8px;
}
