.dateButton {
  border-radius: 1.2rem;
  border: solid 0.08rem var(--bs-secondary-middle-pale);
  font-size: 1rem;

  div {
    color: black;
  }

  i {
    color: var(--bs-secondary);
  }
}
