.activeItem {
  background-color: var(--bs-secondary-pale) !important;
  &:hover {
    background-color: var(--bs-secondary-pale) !important;
  }
}
.item {
  &:active {
    background-color: var(--bs-primary) !important;
  }
}
