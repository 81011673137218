.square {
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 0.2rem;
  border: 0.09rem solid #ffffff;
}

.card {
  cursor: pointer;
  height: auto;
  width: 100%;
}

.multiCard {
  top: -0.4rem;
  left: 0.4rem;
  width: 100%;
  height: auto;
}

.transform2 {
  max-height: 200px;
  overflow-y: scroll;
}

.dropdownItems {
  max-width: 200px;
}
