.table {
  font-size: 0.875rem;
  table-layout: fixed;
}

.header {
  font-size: 0.75rem;
}

.pointer {
  cursor: pointer;
}

.cell {
  padding: 0.75rem !important;
}
