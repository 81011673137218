.container {
  top: 56px;
  height: calc(100vh - 56px);
}

.label {
  font-size: 0.875rem;
}

.value {
  font-size: 1.5rem;
}

.progressBar {
  width: 550px;
}

.tableWrapper {
  width: calc(100vw - 3rem);
  overflow: scroll;
  background-color: white;
}
