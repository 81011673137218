.container {
  font-size: 0.875rem;
  background-color: white;
  min-height: calc(100vh - 71px);
}

.mainContainer {
  width: 60% !important;
}

.list {
  height: 100% !important;
  padding: 0.8rem;
  overflow-y: auto;
}

.placeholderImage {
  max-width: 320px;
}

.square {
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 0.2rem;
  border: 0.09rem solid #ffffff;
}

.row {
  height: calc(100vh - 56px - 38px - 3rem); // 56px: ヘッダー, 38px: タイトル, マージン合計: 3rem
}
