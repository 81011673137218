.square {
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 0.2rem;
  border: 0.09rem solid #ffffff;
}

.transform {
  overflow-y: scroll;
  max-height: 200px;
}

.dropdownItems {
  max-width: 200px;
}
