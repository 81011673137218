.container {
  min-height: calc(100vh - 127px);
}

.tableWrapper {
  max-width: 100%;
  overflow: scroll;
  font-size: 0.875rem;
}

.tableRow {
  height: 50px;
  border-bottom: 1px solid var(--bs-secondary-pale);
}

.tableHeader {
  max-width: 220px !important;
  min-width: 220px !important;
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
  background-clip: padding-box;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    box-shadow: 1.5px 0 1.5px var(--bs-secondary-middle);
  }
}

.tableContent {
  position: relative;
  background-color: white;
  padding: 0;
  z-index: 1;
}
