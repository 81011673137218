.dropdownItem {
  cursor: pointer;
  &:hover {
    background-color: #dcdcdc;
  }
}

.list {
  height: 70vh !important;
  padding: 0.8rem;
  margin-right: 0.8rem;
  overflow: auto;
}

.placeholderImage {
  max-width: 320px;
}
