.dateTable {
  flex: 0 0 350px;
}

.scTable {
  padding: 0;
  overflow-x: scroll;
  white-space: nowrap;
}

.w20 {
  width: 20%;
}

.w80 {
  width: 80%;
}
