.sidebarOpen {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 200;
  width: 350px;
  max-width: 70%;
  height: 100%;
  padding: 70px 0;
  font-size: 1.125rem;
  background: #ffffff;
  transition: all 0.2s ease-in-out 0s;
  transform: translateX(0);
  box-shadow: 6px 0 25px rgba(0, 0, 0, 0.16);
  overflow-y: scroll;
  overflow-x: hidden;
}

.sidebar {
  @extend .sidebarOpen;
  right: -350px;
  .open & {
    transform: translateX(0);
    box-shadow: 6px 0 25px rgba(0, 0, 0, 0.16);
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
}

.square {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 0.2rem;
  border: 0.09rem solid #ffffff;
}
