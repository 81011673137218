.item {
  position: relative;
  cursor: pointer;
  padding-left: 1rem;
  & a {
    color: #000000;
  }
}

.active {
  &:before {
    content: '';
    position: absolute;
    background-color: #0075e3;
    height: 1.3rem;
    top: 1.3rem;
    left: 1rem;
    margin-top: -0.8em;
    width: 0.3em;
  }
}

$header: 56px;
.container {
  display: flex;
  height: calc(100vh - #{$header});
}

.childContainer {
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow-y: auto;
  width: 100%;
}

.sidebar {
  background-color: #fff;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-right-width: 1px;
  font-size: 0.875rem;
  overflow-y: auto;
  min-width: 250px;
}

.previousItem {
  cursor: pointer;
}

.itemLabel {
  padding: 0.5rem 0 0.5rem 2rem;
}

.pathLabel {
  width: 210px;
}
