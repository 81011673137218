.notification {
  position: absolute;
  top: 0;
}

.card {
  width: 650px;
}

.image {
  width: 100%;
}

.svgBackground {
  background: url('sign-up.svg') no-repeat;
  background-size: 100% auto;
  padding: 70px 0;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  color: #ffffff;
}

.logoImage {
  width: 320px;
}
