.icon {
  min-width: 60px;
  min-height: 80px;
}

$color_list: 'silver', 'twilight', 'sky', 'emerald', 'magenta', 'bengal', 'mustard', 'rose', 'violet', 'grass';

@each $color in $color_list {
  .#{$color}-bad {
    @extend .icon;
    background: center / contain no-repeat url('./#{$color}-bad.svg');
  }
}

@each $color in $color_list {
  .#{$color}-good {
    @extend .icon;
    background: center / contain no-repeat url('./#{$color}-good.svg');
  }
}

@each $color in $color_list {
  .#{$color}-normal {
    @extend .icon;
    background: center / contain no-repeat url('./#{$color}-normal.svg');
  }
}
