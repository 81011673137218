.popup {
  padding: 0 !important;
  font-size: 0.875rem !important;
  max-height: 300px;
  max-width: 200px;
  overflow-y: auto;
}

.square {
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 0.2rem;
  border: 0.09rem solid #ffffff;
}
