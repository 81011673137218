.card {
  width: 650px;
  padding: 50px;
}

.imageContainer {
  margin-bottom: 20px;
  text-align: center;
}

.image {
  width: 100px;
}
