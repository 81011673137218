$icomoon-font-family: 'sbc-icon' !default;
$icomoon-font-path: '.' !default;

$icf-shift: '\e92c';
$icf-comment: '\e92a';
$icf-relation: '\e92b';
$icf-downToUp: '\e926';
$icf-upToDown: '\e927';
$icf-leftToRight: '\e928';
$icf-rightToLeft: '\e929';
$icf-larger: '\e924';
$icf-smaller: '\e925';
$icf-percent: '\e923';
$icf-setting: '\e921';
$icf-template: '\e922';
$icf-list: '\e920';
$icf-save: '\e91f';
$icf-cal: '\e902';
$icf-double_carot: '\e91e';
$icf-amount: '\e900';
$icf-by_time: '\e901';
$icf-compare: '\e903';
$icf-delete: '\e904';
$icf-edit: '\e905';
$icf-filter: '\e906';
$icf-Move: '\e907';
$icf-others: '\e908';
$icf-member-path1: '\e909';
$icf-member-path2: '\e90a';
$icf-member-path3: '\e90b';
$icf-member-path4: '\e90c';
$icf-plus: '\e90d';
$icf-replace: '\e90e';
$icf-schedule: '\e90f';
$icf-search: '\e910';
$icf-updated: '\e911';
$icf-check: '\e912';
$icf-hint: '\e913';
$icf-info: '\e914';
$icf-alert: '\e915';
$icf-Menu: '\e916';
$icf-close: '\e917';
$icf-carot_down: '\e918';
$icf-carot_left: '\e919';
$icf-carot_right: '\e91a';
$icf-carot_up: '\e91b';
$icf-chevron_left: '\e91c';
$icf-chevron_right: '\e91d';
