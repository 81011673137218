.icon {
  position: absolute;
  display: inline-block;
  top: 50%;
  right: 5px;
  transform: translate(0, -50%);
  color: var(--bs-light-gray);
}

.reactDatePickerWrapper {
  width: 100%;
}

.reactDatePickerPopper {
  z-index: 1000;
}
