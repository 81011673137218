.toggleButtonDisabled {
  padding: 0.1rem 0.6rem;
  margin-right: 0.5rem;
  border-radius: 0.8rem;
  border: solid 0.08rem #dcdcdc;
  background-color: #dcdcdc;
  font-size: 0.875rem;
}

.square {
  display: inline-block;
  width: 0.9rem;
  min-width: 0.9rem;
  height: 0.9rem;
  border-radius: 0.2rem;
  border: 0.09rem solid #ffffff;
}

.dashedSquare {
  display: inline-block;
  width: 0.9rem;
  min-width: 0.9rem;
  height: 0.9rem;
  border-radius: 0.2rem;
  border: 0.09rem dashed #ffffff;
}
