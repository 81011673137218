.container {
  height: 38px;
  display: flex;
  white-space: nowrap !important;
}
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  border-right: 2px solid var(--bs-secondary-pale);
  &:last-child {
    border-right: none;
  }
}
