.iconButton {
  height: 30px;
  border: var(--bs-secondary) solid 1px;
  border-radius: 0.2rem;
  outline: none !important;
  width: 36px;
}

.closeIconButton {
  @extend .iconButton;
  background-color: white;
  i {
    color: var(--bs-secondary);
  }
}

.openIconButton {
  @extend .iconButton;
  background-color: var(--bs-secondary);
  i {
    color: white;
  }
}
