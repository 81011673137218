.container {
  position: relative;
  top: -50px;
  margin-bottom: -50px;
  height: 50px;
  display: flex;
}

.box {
  width: 100%;
  height: 50px;
  margin: 0 0.5px;
  border-radius: 4px;
}

.inputBox {
  position: absolute;
  top: 0;
  height: 50px;
  opacity: 0.8;
  border-radius: 4px;
  color: white;
  padding: 3px 7px;
}
