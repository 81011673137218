$tableHeight: 239px;
.container {
  max-width: max-content;
  max-height: calc(100vh - #{$tableHeight});
  overflow: auto;
  border: 1px solid var(--bs-secondary-pale);
}

.timeHeader {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  border-bottom: 1px solid var(--bs-secondary-pale);
}

.tableRow {
  height: 2.5rem;
  overflow-y: clip;
  border-top: 1px solid var(--bs-secondary-pale);
  user-select: none;
  &:hover .tableHeader {
    background-color: var(--bs-primary-pale);
  }
  &:hover .tableContent {
    background-color: var(--bs-primary-pale);
  }
}

.tableHeader {
  min-width: 200px;
  max-width: 200px;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: #ffffff;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    box-shadow: 1.5px 0 1.5px var(--bs-secondary-middle);
  }
}

.tableContent {
  position: relative;
  padding: 0;
  z-index: 1;
}
