.barContainer {
  position: relative;
  height: 40px;
}

.barBg {
  position: absolute;
  height: 16px;
  background-color: var(--bs-secondary-pale);
  width: 100%;
  border-radius: 2px;
}

.barRecord {
  position: absolute;
  height: 16px;
  background-color: var(--bs-primary-middle);
  border-radius: 2px;
}

.barRecordWarning {
  background-color: var(--bs-danger);
}

.barPlanLine {
  position: absolute;
  height: 16px;
  border-right: var(--bs-secondary) 2px solid;
}

.barIndicator {
  position: absolute;
  right: -7px;
  bottom: -12px;
}
