.list {
  top: 71px;
  height: calc(100vh - 150px) !important;
  padding: 0.8rem;
  overflow: auto;
}

.placeholderImage {
  max-width: 320px;
}
