.root {
  display: flex;
  align-items: center;
}

.fileInput {
  display: none !important;
}

.button {
  margin: 0;
}

.msg {
  margin-left: 20px;
}

.msgEmpty {
  color: var(--bs-secondary);
}
