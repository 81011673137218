.placeholderContainer {
  height: 320px;
  max-height: 320px;
}

.placeholderImageContainer {
  width: 40%;
  text-align: right;
}

.placeholderImage {
  max-width: 320px;
}

.placeholderTextContainer {
  white-space: pre-wrap;
  width: 60%;
  padding: 0 30px 0 30px;
}

.placeholderBoldText {
  font-size: 1.25rem;
  font-weight: bold;
}
