.pivotMenu {
  display: flex;
  align-items: stretch;
  &::after {
    display: block;
    content: '';
    flex-grow: 1;
    border-bottom: 1px solid #a5adb5;
  }
}

.pivotBody {
  flex-grow: 1;
  position: relative;
  padding: 1rem 0;
}

.pivotItem {
  display: block;
}

.label {
  padding: 0.5em 0.7em;
  color: #a5adb5;
  text-align: center;
  margin: 0;
  border-bottom: 1px solid;
  cursor: pointer;
  &[data-is-active='true'] {
    color: #0075e3;
    border-bottom: 3px solid #0075e3;
  }
}
